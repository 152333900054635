import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import PageHeader from '../components/pageheader'
import CallToAction from '../components/calltoaction'
import FlexContent from '../components/flexContent'
import Card from '../components/card'

const SingleNews = ({
    data: {
        page,
        navigation,
        cta
    },
    pageContext: {
        locale
    }
}) => {

    return (
        <Layout locale={locale} navigation={navigation} page={page}>
            <PageHeader page={page} locale={locale}/>
            <section className='container'>
                <FlexContent page={page}/>
            </section>
            <Card className='masthead__cards grid--four container' page={page}/>
            <CallToAction cta={cta} type='vertical'/>
        </Layout>
    );
}

export default SingleNews

export const query = graphql`
    query($id: String!, $locale: String) {
         page: contentfulKeyMarkets( node_locale: { eq: $locale }, id: { eq: $id } ) {
            ...KeyMarketsFragment
        }
        navigation: contentfulNavigation (node_locale: {eq: $locale}) {
            ...NavigationFragment
        }  
        cta: allContentfulCallToAction(filter: {node_locale: {eq: $locale}, pageLocation: {eq: "register"}}) {  
            edges {
                node {
                    ...CallToActionFragment
                }
            }
        }
    }
`

